// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstellung-js": () => import("./../../../src/pages/ausstellung.js" /* webpackChunkName: "component---src-pages-ausstellung-js" */),
  "component---src-pages-barrierefreiheitserklaerung-js": () => import("./../../../src/pages/barrierefreiheitserklaerung.js" /* webpackChunkName: "component---src-pages-barrierefreiheitserklaerung-js" */),
  "component---src-pages-biografie-behr-js": () => import("./../../../src/pages/biografie_behr.js" /* webpackChunkName: "component---src-pages-biografie-behr-js" */),
  "component---src-pages-biografie-lotzer-js": () => import("./../../../src/pages/biografie_lotzer.js" /* webpackChunkName: "component---src-pages-biografie-lotzer-js" */),
  "component---src-pages-biografie-schappeler-js": () => import("./../../../src/pages/biografie_schappeler.js" /* webpackChunkName: "component---src-pages-biografie-schappeler-js" */),
  "component---src-pages-carl-legien-js": () => import("./../../../src/pages/Carl_Legien.js" /* webpackChunkName: "component---src-pages-carl-legien-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gedenkobjekt-js": () => import("./../../../src/pages/gedenkobjekt.js" /* webpackChunkName: "component---src-pages-gedenkobjekt-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interaktive-karte-js": () => import("./../../../src/pages/interaktive-karte.js" /* webpackChunkName: "component---src-pages-interaktive-karte-js" */),
  "component---src-pages-orte-bamberg-js": () => import("./../../../src/pages/orte/bamberg.js" /* webpackChunkName: "component---src-pages-orte-bamberg-js" */),
  "component---src-pages-orte-ermershausen-js": () => import("./../../../src/pages/orte/ermershausen.js" /* webpackChunkName: "component---src-pages-orte-ermershausen-js" */),
  "component---src-pages-orte-gaibach-js": () => import("./../../../src/pages/orte/gaibach.js" /* webpackChunkName: "component---src-pages-orte-gaibach-js" */),
  "component---src-pages-orte-herrenchiemsee-js": () => import("./../../../src/pages/orte/herrenchiemsee.js" /* webpackChunkName: "component---src-pages-orte-herrenchiemsee-js" */),
  "component---src-pages-orte-memmingen-js": () => import("./../../../src/pages/orte/memmingen.js" /* webpackChunkName: "component---src-pages-orte-memmingen-js" */),
  "component---src-pages-orte-muenchen-lmu-js": () => import("./../../../src/pages/orte/muenchen-lmu.js" /* webpackChunkName: "component---src-pages-orte-muenchen-lmu-js" */),
  "component---src-pages-orte-muenchen-maximilianeum-js": () => import("./../../../src/pages/orte/muenchen-maximilianeum.js" /* webpackChunkName: "component---src-pages-orte-muenchen-maximilianeum-js" */),
  "component---src-pages-orte-muenchen-prannerstrasse-js": () => import("./../../../src/pages/orte/muenchen-prannerstrasse.js" /* webpackChunkName: "component---src-pages-orte-muenchen-prannerstrasse-js" */),
  "component---src-pages-orte-nuernberg-js": () => import("./../../../src/pages/orte/nuernberg.js" /* webpackChunkName: "component---src-pages-orte-nuernberg-js" */),
  "component---src-pages-orte-passau-js": () => import("./../../../src/pages/orte/passau.js" /* webpackChunkName: "component---src-pages-orte-passau-js" */),
  "component---src-pages-orte-regensburg-js": () => import("./../../../src/pages/orte/regensburg.js" /* webpackChunkName: "component---src-pages-orte-regensburg-js" */),
  "component---src-pages-orte-vilshofen-js": () => import("./../../../src/pages/orte/vilshofen.js" /* webpackChunkName: "component---src-pages-orte-vilshofen-js" */),
  "component---src-pages-orte-wohlmuthshuell-js": () => import("./../../../src/pages/orte/wohlmuthshuell.js" /* webpackChunkName: "component---src-pages-orte-wohlmuthshuell-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-startscreen-js": () => import("./../../../src/pages/startscreen.js" /* webpackChunkName: "component---src-pages-startscreen-js" */),
  "component---src-pages-verfassung-alt-js": () => import("./../../../src/pages/verfassung_alt.js" /* webpackChunkName: "component---src-pages-verfassung-alt-js" */),
  "component---src-pages-verfassung-neu-js": () => import("./../../../src/pages/verfassung_neu.js" /* webpackChunkName: "component---src-pages-verfassung-neu-js" */),
  "component---src-pages-wissenschaftlicher-beirat-js": () => import("./../../../src/pages/wissenschaftlicher-beirat.js" /* webpackChunkName: "component---src-pages-wissenschaftlicher-beirat-js" */)
}

